import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCss3, faGit, faHtml5, faJava, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
    const[letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
          clearTimeout(timer);
        };
      }, []);
    return (
      <>
      
        <div className='container about-page'>
        
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters letterClass={letterClass} strArray={['Ü', 'b', 'e', 'r', ' ', 'M', 'i', 'c', 'h'] } idx={15} />
                </h1>
                <h2>Willkommen in meinem Portfolio! Dies ist der Ort, an dem ich meine Leidenschaft für Softwareentwicklung und kreative Ideen zum Leben erwecke. Hier können Sie eine Auswahl meiner eigenen kleinen Projekte entdecken</h2>
                <h2>Jedes dieser Projekte repräsentiert meine Hingabe und meinen stetigen Fortschritt in der Welt der Softwareentwicklung. Von Anfangsideen bis zur vollständigen Umsetzung – jedes Projekt erzählt eine Geschichte</h2>
                <h2>Klicken Sie auf die Projekte, um mehr darüber zu erfahren. Sie können die verschiedenen Technologien und Konzepte erkunden, die in jedem Projekt umgesetzt wurden.</h2>
            </div>
            <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faJava} color="#ff1e00" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGit} color="#EC4D28" />
            </div>
          </div>
        </div>
        </div>
        <Loader type="ball-clip-rotate-multiple" />
        </>
    )
}

export default About