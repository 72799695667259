import React from 'react';
import './index.scss';
import logo from '../../../assets/images/imu.png';

const Logo = () => {
  return (
    <div className="logo-container">
      <div className="shadow">
        <img className="animated-logo" src={logo} alt="JavaScript Developer" />
      </div>
    </div>
  );
};

export default Logo;