import Loader from 'react-loaders';
import './index.scss'
import { useState, useEffect } from 'react';
import Slider from './Slider';
import { SliderData } from './Slider/SliderData';



const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
  

    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
          clearTimeout(timer);
        };
      }, []);
    
    return (
        <>
        <div className='container portfolio-page'>
            <div className="text-zone">
            <h1>
            <span className={letterClass}>P</span>
            <span className={`${letterClass} _12`}>r</span>
            <span className={`${letterClass} _13`}>o</span>
            <span className={`${letterClass} _14`}>j</span>
            <span className={`${letterClass} _15`}>e</span>
            <span className={`${letterClass} _16`}>k</span>
            <span className={`${letterClass} _17`}>t</span>
            <span className={`${letterClass} _18`}>e</span>
            </h1>
            <Slider slides={SliderData} />
            
         
            </div>
          
        </div>
        
        <Loader type="ball-clip-rotate-multiple" />
        </>
    )
}

export default Portfolio;