import React, { useRef, useEffect } from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCss3, faGit, faHtml5, faJava, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons'

const Modal = ({ isOpen, closeModal, details }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, closeModal]);

  if (!isOpen) {
    return null;
  }

  const handleClick = () => {
    // Open the website in a new tab
    window.open(details.link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="modal-container">
      <div ref={modalRef} className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="modal-content">
          <img src={details.image} alt={details.title} className="modal-image" />
          <div className="vertical-line"></div>
          <div className="modal-text">
            <h2>{details.title}</h2>
            <p>{details.description}</p>
            <p>Verwendete Technologien:</p>
            <p className="technologies">
              {details.technologies.map((technology, index) => (
                <span key={index} className="technology">
                  {technology.icon} {technology.name}
                </span>
              ))}
            </p>
            <button className="website-button" onClick={handleClick}>
              Öffnen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;