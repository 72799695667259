import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome, faLaptopCode, faUser } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const Bar = () => (
<div className='nav-bar'>
    <nav>
        <NavLink exact="true" activeclassname="active" to="/">
            <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>
        </NavLink>
        <NavLink exact="true" activeclassname="active" className="about-link" to="/about">
            <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>
        </NavLink>
        <NavLink exact="true" activeclassname="active" className="portfolio-link" to="/portfolio">
            <FontAwesomeIcon icon={faLaptopCode} color="#4d4d4e"/>
        </NavLink>
    </nav>

    <ul>
        <li>
            <a target="_blank" rel="noreferrer" href="mailto:jnnknrdmnn@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
            </a>
        </li>
        <li>
            <a target="_blank" rel="noreferrer" href="https://github.com/carbonhrz?tab=repositories">
                <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
            </a>
        </li>
    </ul>
</div>
    )

export default Bar