import './index.scss';
import LogoTitle from '../../assets/images/jay.png';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import Logo from './Logo';
import Loader from 'react-loaders';


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')


    const nameArray = ['a', 'n', 'n', 'i', 'k']
    const description = ['d','e','v','e','l','o','p','e','r']

    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);

        return () => {
          clearTimeout(timer);
        };
      }, []);
    

    return (
      <>
        <div className="container home-page">
        <div className="text-zone">
            <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br/>
            <span className={letterClass}>I</span>
            <span className={`${letterClass} _13`}>c</span>
            <span className={`${letterClass} _14`}>h</span>
            <span className={`${letterClass} _15`}> </span>
            <span className={`${letterClass} _16`}>b</span>
            <span className={`${letterClass} _17`}>i</span>
            <span className={`${letterClass} _18`}>n</span>
            <img src={LogoTitle} alt="developer" />
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={19} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={description} idx={27} />
            </h1>
            <h2>Developer</h2>
            <a href="mailto:jnnknrdmnn@gmail.com" className='flat-button'>CONTACT ME</a>
        </div>
        <Loader type="ball-clip-rotate-multiple" />
        <Logo />
        
        </div>

        <Loader type="ball-clip-rotate-multiple" />
        </>
    )
   
}

export default Home