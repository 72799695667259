import React, { useState } from 'react';
import { faPlay, faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';
import Modal from '../Modal';

const Slider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='slider'>
      <FontAwesomeIcon icon={faPlay} className='left-arrow' onClick={prevSlide} />
      <FontAwesomeIcon icon={faBackward} className='right-arrow' onClick={nextSlide} />
      <div className="slide-container">
        {slides.map((slide, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
              onClick={() => {
                openModal();
                setCurrent(index);
              }}
            >
              {index === current && (
                <img src={slide.image} alt='' className='image' />
              )}
            </div>
          );
        })}
      </div>

      {/* Render the Modal component conditionally */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} closeModal={closeModal} details={slides[current]} />
          
      )}
    </section>
  );
};

export default Slider;